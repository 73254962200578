import React, { useState, useEffect, useRef } from "react";
import style from "./Dashboard.module.css";
import { IoPlay } from "react-icons/io5";
import welcomeImage from "../../assests/Group 34.png";
import { FaArrowRightLong } from "react-icons/fa6";
import ActiveCalendar from "../../assests/activeCalendar.png";
import ActiveWorkOut from "../../assests/ActiveWeightlifting-sport-exercise-gym (1).png";
import Calendar from "../../assests/Calendar.png";
import WorkOut from "../../assests/Weightlifting-sport-exercise-gym.png";
import Chart from "chart.js/auto";
import { useNavigate } from "react-router-dom";
import { baseurl } from "../Url";
import Loader from "../Loder/Loader";
import TimerImage from "../../assests/Chronometer.png";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from 'react-redux';

const Dashboard = () => {
  const navigate=useNavigate()
 

  const chartContainer = useRef(null);
  const chartInstance = useRef(null);

  const { userDetails, status: userStatus, error: userError } = useSelector((state) => state.user);

  const [learningData, setLearningData] = useState({});
  const [loading, setLoading] = useState(true);
  const [dailYDrill, setDailyDrill] = useState(false);
  const [weeklyWorkout, setWeeklyWorkout] = useState(false);
  const [snoozePopup,setSnoozePopup]=useState(false)
  const [result,setResult]=useState({})
  const [activePopup, setActivePopup] = useState(null);
  const [videoPopup,setVideoPopup]=useState(false)
  const [currentData,setCurrentData]=useState({})
  const [sessionExpire,setSessionExpire]=useState(false);

  const totalModules = learningData.Topics ? learningData.Topics.length : 0;
  const totalTopics = learningData.Topics
    ? learningData.Topics.reduce((acc, module) => acc + module.topics.length, 0)
    : 0;
  const completionPercentage = learningData.percentage_completion ? learningData.percentage_completion : 1;

  const currentTopic = learningData.Topics
  ? learningData.Topics.find(topic =>
      topic.topics.some(childTopic => childTopic.status !== "complete")
    ) || learningData.Topics[0] 
  : null;

const currentModuleName = currentTopic ? currentTopic.module_name : 'Module 1: Sentences';

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const currentDate = getCurrentDate();

  const handleSnooze = (minutes) => {
    if (activePopup === 'dailyDrill') {
      snoozeCreate(minutes,"daily");
    } else if (activePopup === 'weeklyWorkout') {
      snoozeCreate(minutes,"weekly");
    }
   
  };

  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("access-token");
    localStorage.removeItem("ClubID");
    localStorage.removeItem("ORGID");
    localStorage.removeItem("USERID");
    localStorage.removeItem("mentor");
    localStorage.removeItem("learnertoken");
    localStorage.removeItem("learneruserid");
    localStorage.removeItem("username");
    localStorage.removeItem("newLearningId");
    localStorage.removeItem("MainLevelId");
    localStorage.removeItem("SubLevelId");
    localStorage.removeItem("clickedItem");
    localStorage.removeItem("clickeditemDes");
    localStorage.removeItem("functionCalled");
    localStorage.removeItem("rzp_checkout_anon_id");
    localStorage.removeItem("rzp_device_id");
    localStorage.removeItem("countrydetails");
    localStorage.removeItem("timezone");
    localStorage.removeItem("currency");
    localStorage.removeItem("adminmapped");
    localStorage.removeItem('dailySnoozeTriggered');
    localStorage.removeItem('weeklySnoozeTriggered');
    localStorage.removeItem('hasShownDailyDrill');
    localStorage.removeItem("trialUser")
    localStorage.removeItem("categoryID")
    navigate("/login");
    window.location.reload();
  };
  


  async function learningList() {
    await fetch(`${baseurl}/userlearning/details`, {
      method: "POST",
      body: JSON.stringify({ user_id: userDetails&&userDetails._id?.$oid }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails&&userDetails.auth_token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setLearningData(data.data[0]);
          localStorage.setItem("categoryID",data.data[0]?.Topics[0].category_id)
        }
      });
  }
  async function currentStatus() {
    await fetch(`${baseurl}/user/latest-status`, {
      method: "POST",
      body: JSON.stringify({ user_id: userDetails&&userDetails._id?.$oid }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails&&userDetails.auth_token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setCurrentData(data.data);
          setTimeout(() => {
            setLoading(false)
          }, 1000);
        }
      });
  }

  async function dailyWeeklyExam(Type) {
     const type=Type?Type:"daily"
    await fetch(`${baseurl}/check/daily/weakly/exams`, {
      method: "POST",
      body: JSON.stringify({
        org_id: userDetails&&userDetails.org_id,
        user_id: userDetails&&userDetails._id?.$oid ,
        date: currentDate,
        type: type,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails&&userDetails.auth_token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          setResult(data.data[0])

          if(Type&&Type==="daily"&&data.data[0]?.Daily_Drill?.DailyDrill === true && data.data[0]?.Daily_Drill?.snoozed === false){
            setDailyDrill(true)
          }
          if(Type&&Type==="weekly"&&data.data[0]?.WeeklyDrill?.WeeklyDrill === true && data.data[0]?.WeeklyDrill?.snoozed === false){
            setWeeklyWorkout(true)
          }
          

          if (
            data.data[0]?.Daily_Drill?.DailyDrill === true &&
            data.data[0]?.Daily_Drill?.snoozed === false &&
            !JSON.parse(localStorage.getItem('hasShownDailyDrill')) &&
            localStorage.getItem('cookie-consent') === 'accepted'
          ) {
            setDailyDrill(true);
            localStorage.setItem('hasShownDailyDrill',true);
          }
         
            if (data.data[0]?.Daily_Drill?.DailyDrill === true && data.data[0]?.Daily_Drill?.snoozed === false &&JSON.parse(localStorage.getItem('dailySnoozeTriggered'))) {
              setDailyDrill(true);
              localStorage.removeItem('dailySnoozeTriggered');
            }
          
  
            if (data.data[0]?.WeeklyDrill?.WeeklyDrill === true && data.data[0]?.WeeklyDrill?.snoozed === false && JSON.parse(localStorage.getItem('weeklySnoozeTriggered'))) {
              setWeeklyWorkout(true);
              localStorage.removeItem('weeklySnoozeTriggered');
              
            
          }
        }else{
          if(data.description==="Token has expired"){
            setSessionExpire(true)
          }
        }
      });
  }

  async function snoozeCreate(min,type) {
    await fetch(`${baseurl}/snooze/create`, {
      method: "POST",
      body: JSON.stringify({
        org_id:  userDetails&&userDetails.org_id,
        user_id: userDetails&&userDetails._id?.$oid ,
        snooze_min: min.toString(),
        exam_type: type,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails&&userDetails.auth_token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setSnoozePopup(false);
          setActivePopup(null); 
          dailyWeeklyExam();
          if (type === "daily") {
            localStorage.setItem('dailySnoozeTriggered', true);
          } else if (type === "weekly") {
            localStorage.setItem('weeklySnoozeTriggered', true);
          }
        }
      });
  }

  useEffect(() => {
    if (userDetails) {
      learningList();
      dailyWeeklyExam();
      currentStatus();
    }
  }, [userDetails]);

  useEffect(() => {
    // Cleanup previous chart instance
    if (chartInstance.current !== null) {
      chartInstance.current.destroy();
    }

    // Create new chart instance
    if (chartContainer.current) {
      chartInstance.current = new Chart(chartContainer.current, {
        type: "doughnut",
        data: {
          datasets: [
            {
              label: "Completion",
              data: [currentData?.percentage, 100 - currentData?.percentage],
              backgroundColor: ["#FFBE00", "#FFF"],
            },
          ],
        },
        options: {
          responsive: true,
          cutout: "85%",
          plugins: {
            legend: {
              position: "top",
            },
            tooltip: {
              enabled: false,
            },
          },
          hover: {
            mode: null,
          },
        },
      });
    }

    // Cleanup function
    return () => {
      if (chartInstance.current !== null) {
        chartInstance.current.destroy();
      }
    };
  }, [currentData]);

  useEffect(() => {
    const interval = setInterval(() => {
      dailyWeeklyExam();
    }, 30000); 
  
    return () => clearInterval(interval);
  }, [userDetails])
  

  return (
    <div className={style.container}>
      {loading?<Loader/>:""}
      <div className={style.dashboardSection}>
        <div className={style.welcomeSection}>
          <div className={style.welcomeSectionLeft}>
            <p>Hello {userStatus === 'succeeded' && userDetails &&userDetails.FirstName}!</p>
           {userDetails &&userDetails.user_type==="Trail"?<span>Welcome to your free GrammarLocker trial!            
           </span>: <span>Welcome back</span>}
          </div>
          <div className={style.welcomeSectionRight}>
            <div className={style.welcomeImageContainer}>
              <div className={style.welcomeImage}>
                <img src={welcomeImage} alt="welcome" />
              </div>
            </div>
          </div>
        </div>
        <div className={style.videoSection}>
          <div className={style.videoSectionLeft}>
            <p>How {learningData?.category_details?learningData.category_details:"GrammarLocker"} works</p>
          </div>
          <div className={style.videoSectionRight}>
            <div onClick={()=>{
              setVideoPopup(true)
            }} className={style.videoButtonSection}>
              <IoPlay /> <p>Watch Now</p>
            </div>
          </div>
        </div>
        <div className={style.activitySection}>
          <div className={style.activitySectionLeft}>
            <div className={style.activitySectionLeftTop}>
              <h4>{learningData?.category_details?learningData.category_details:"GrammarLocker"}</h4>
              <p>
                The ultimate online, English grammar workbook. An
                interactive way to bridge your knowledge gaps and transform you
                into a confident and accurate communicator.
              </p>
            </div>
            <div className={style.activitySectionLeftBottom}>
              <div className={style.moduleText}>
                <p>{totalModules}</p>
                <span>Modules</span>
              </div>
              <div className={style.moduleText}>
                <p>{totalTopics}</p>
                <span>Topics</span>
              </div>
              <div className={style.exploreButton} onClick={()=>navigate("/new/learnings")}>
                <p>Explore More</p>
                <FaArrowRightLong />
              </div>
            </div>
          </div>
          <div className={style.activitySectionRight}>
            <div className={style.activityRightHeader}>
              {/* <p>Activities</p> {result?.Daily_Drill?.last_attend_date!=="0"?<span>You are {result?.Daily_Drill?.last_attend_date} day overdue</span>:""} */}
            </div>
            <div className={style.activityCardContainer}>
              <div
                onClick={() => {
                    dailyWeeklyExam("daily")
                }}
                className={`${style.activityCard} 
              ${result?.Daily_Drill?.DailyDrill === false || result?.Daily_Drill?.snoozed === true 
                ? style.disabledCard 
                : style.dailyCard} 
              `}
              >
                <div className={style.activityCardImage}>
                  {result?.Daily_Drill?.DailyDrill === false || result?.Daily_Drill?.snoozed === true?
                  <img src={Calendar} alt="calendar" />
                  :<img src={ActiveCalendar} alt="calendar" />}
                </div>
                <div className={style.activityCardText}>
                  <p>Daily drill</p>
                </div>
              </div>
              <div
                className={`${style.activityCard} ${result?.WeeklyDrill?.WeeklyDrill === false || result?.WeeklyDrill?.snoozed === true?style.disabledCard:style.weeklyCard}`}
                onClick={() => {
                  
                    dailyWeeklyExam("weekly");
                  
                }} 
              >
                <div className={style.activityCardImage}>
                {result?.WeeklyDrill?.WeeklyDrill === false || result?.WeeklyDrill?.snoozed === true?
                  <img src={WorkOut} alt="calendar" />
                  :<img src={ActiveWorkOut} alt="calendar" />}
                </div>
                <div className={style.activityCardText}>
                  <p>Weekly workout</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.statusSection}>
        <div className={style.statusSectionLeft}>
  {/* Check if either latest_completed or latest_inprogress has data */}
  {(currentData?.latest_completed?.module_name || currentData?.latest_inprogress?.module_name) && (
    <>
      {currentData?.latest_completed?.module_name && (
        <>
          <div className={style.statusHeading}>
            <p style={{ color: "#00DFC7" }}>Recently completed</p>
          </div>
          <div className={style.topicCard}>
            <div className={style.topicCardLeft}>
              <p>
                {`${currentData.latest_completed.module_name || ""} | ${currentData.latest_completed.topic_value || ""}`}
              </p>
            </div>
          </div>
        </>
      )}

      {currentData?.latest_inprogress?.module_name && (
        <>
          <div className={style.statusHeading}>
            <p style={{ color: "#FFBE00" }}>In-progress</p>
          </div>
          <div className={style.topicCard}>
            <div className={style.topicCardLeft}>
              <p>
                {`${currentData.latest_inprogress?.module_name || ""} | ${currentData.latest_inprogress.topic_value || ""}`}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  )}
</div>


          <div className={style.statusSectionRight}>
            <div className={style.statusSectionRightContent}>
              <div className={style.statusRightHeading}>
                <p>Overall progress</p>
                <span>You have completed {Math.round(currentData?.percentage)}%
                of GrammarLocker</span>
              </div>
              
              <div className={style.statusRightButton}>
                <p onClick={()=>navigate("/new/learnings")}>Explore more</p>
              </div>
            </div>
            <div className={style.statusSectionRightGraph}>
              <canvas ref={chartContainer} />
              <div
                className={style.percentageLabel}
              >
                {currentData?.percentage
                  ? Math.round(currentData?.percentage)
                  : 0}
                %
              </div>
            </div>
          </div>
        </div>
      </div>
      {dailYDrill ? (
        <>
          <div className={style.popupOverlay} onClick={()=>{
            setDailyDrill(false)
          }}></div>
          <div className={style.Popup}>
            <div className={style.popupHeader}>
              <div className={style.popupHeaderIcon}>
                <img src={ActiveCalendar} alt="calendar" />
              </div>
              <div className={style.popupMainHeader}>
                <p>Daily Drill</p>
              </div>
              <div className={style.popupSubHeader}>
                <p>
                  Sharpen your skills daily with quick, focused exercises.
                  <br />
                  Practice makes perfect!
                </p>
              </div>
            </div>
            <div className={style.popupBody}>
            <div className={style.popupBodyTop}>
            <div className={style.popupBodyCard}>
              <span>{result?.Daily_Drill?.no_of_questions}</span><p>Questions</p>
            </div>
            <div className={style.popupBodyCard}>
              <img src={TimerImage} alt="timer"/><p>{result?.Daily_Drill?.alloted_time} mins</p>
            </div>
            </div>
            <div className={style.popupBodyBottom}>
              <p>Are you ready to take the daily drill?</p>
            </div>
            </div>
            <div className={style.PopupButton}>
              <button className={style.snoozeButton} onClick={() => {
                setDailyDrill(false);
                setActivePopup('dailyDrill');
                setSnoozePopup(true);
              }}>
                Snooze
              </button>
              <button className={style.yesButton} onClick={() => {
                navigate("/daily/drill");
                window.location.reload();
              }}>
                Yes
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {weeklyWorkout ? (
        <>
          <div className={style.popupOverlay}  onClick={()=>{
            setWeeklyWorkout(false)
          }}></div>
          <div className={style.Popup}>
            <div className={style.popupHeader}>
              <div className={style.popupHeaderIcon}>
                <img src={ActiveWorkOut} alt="calendar" />
              </div>
              <div className={style.popupMainHeader}>
                <p>Weekly Workout</p>
              </div>
              <div className={style.popupSubHeader}>
                <p>
                  Sharpen your skills daily with quick, focused exercises.
                  <br />
                  Practice makes perfect!
                </p>
              </div>
            </div>
            <div className={style.popupBody}>
            <div className={style.popupBodyTop}>
            <div className={style.popupBodyCard}>
              <span>{result?.WeeklyDrill?.no_of_questions}</span><p>Questions</p>
            </div>
            <div className={style.popupBodyCard}>
              <img src={TimerImage} alt="timer"/><p>{result?.WeeklyDrill?.alloted_time} mins</p>
            </div>
            </div>
            <div className={style.popupBodyBottom}>
              <p>Are you ready to take the weekly workout?</p>
            </div>
            </div>
            <div className={style.PopupButton}>
              <button className={style.snoozeButton} onClick={() => {
                setWeeklyWorkout(false);
                setActivePopup('weeklyWorkout');
                setSnoozePopup(true);
              }}>
                Snooze
              </button>
              <button style={{background:"#00DFC7"}} className={style.yesButton} onClick={() => {
                navigate("/weekly/workout");
                window.location.reload();
              }}>
                Yes
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {snoozePopup ? (
        <>
          <div className={style.popupOverlay}  onClick={()=>{
            setSnoozePopup(false)
            setActivePopup(null)
          }}></div>
          <div className={style.Popup}>
          <div className={style.snoozeHeader}>
            <p>When should we remind you for the {activePopup === 'dailyDrill' ? 'Daily Drill' : 'Weekly Workout'}?</p>
          </div>
            <div className={style.snoozeContainer}>
            <div onClick={() => handleSnooze(10)} className={style.snoozeCard}>
              <p>In 10 min</p>
            </div>
            <div onClick={() => handleSnooze(30)} className={style.snoozeCard}>
              <p>In 30 min</p>
            </div>
            <div onClick={() => handleSnooze(60)} className={style.snoozeCard}>
              <p>In 60 min</p>
            </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {videoPopup&&(
        <>
        <div className={style.popupOverlay}></div>
        <div className={style.videoPopup}>
        <div className={style.videoContainer}>
          <div className={style.videoClose}>
            <AiOutlineClose onClick={()=>setVideoPopup(false)}/>
          </div>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/ahPJndSMICQ?autoplay=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        </div>
        </>
      )}

{sessionExpire ? (
        <>
        <div className={style.popupOverlay}></div>
          <div className={style.expirePopup}>
            <div className={style.expirePopupText}>
              <p>Your session expired please login again</p>
            </div>
            <div className={style.expirePopupButton}>
              <button
              className={style.yesButton}
                onClick={(e) => {
                  e.preventDefault();
                  handleLogout(e);
                }}
              >
                Login
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

    </div>
  );
};

export default Dashboard;
