import React, { useState, useEffect } from "react";
import style from "./Cookie.module.css";
import cookieImage from "../../assests/bitte_cokkie.png";
import Switch from "react-switch";
import DisabledCookieImage from '../../assests/disabledCookie.png' 

const Cookie = () => {

  const consent = localStorage.getItem('cookie-consent');
  const [privacyPopUp, setPrivacyPopup] = useState(false);

  const [checked, setChecked] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    functional: false,
    performance: false,
  });
  const [isVisible, setIsVisible] = useState(false);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const handleAccept = () => {
    localStorage.setItem('cookie-consent', 'accepted');
    setIsVisible(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookie-consent', 'rejected');
    setIsVisible(false);
  };

  const handleSwitchChange = (type, nextChecked) => {
    setCookiePreferences((prev) => ({
      ...prev,
      [type]: nextChecked,
    }));
  };

  useEffect(() => {
    if (consent) {
      setIsVisible(false);
    }
    else{
      setIsVisible(true)
    }
  }, [consent]);

  return (
    isVisible && (
    <div className={style.container}>
      <div className={style.banner} style={{backgroundColor:privacyPopUp?"#898890":""}}>
        <div className={style.bannerLeft}>
          <div className={style.cookieImageOne}>
            <img src={privacyPopUp?DisabledCookieImage:cookieImage} alt="cookie" />
          </div>
          <div className={style.cookieImageTwo}>
            <img src={privacyPopUp?DisabledCookieImage:cookieImage} alt="cookie" />
          </div>
          <div className={style.cookieImageThree}>
            <img src={privacyPopUp?DisabledCookieImage:cookieImage} alt="cookie" />
          </div>
        </div>
        <div className={style.bannerRight}>
          <div className={style.cookieText}>
            <p>
              By clicking “Accept all”, you agree to the storing of cookies on
              your device to enhance site navigation , analyze site usage, and
              assist in our marketing efforts. <span>Privacy policy</span>
            </p>
          </div>
          <div className={style.cookieBottomContent}>
            <div className={style.cookieBottomLeftContent}>
              <p
                onClick={() => {
                  setPrivacyPopup(true);
                }}
              >
                Manage cookie settings
              </p>
            </div>
            <div className={`${style.cookieBottomRightContent} ${privacyPopUp ? style.disabledButton : ""}`}>
              <button onClick={handleAccept}>Accept all</button>
              <button onClick={handleReject}>Reject all</button>
            </div>
          </div>
        </div>
      </div>
      {privacyPopUp ? (
        <>
          <div className={style.overlay}></div>
          <div className={style.privacyPopUp}>
            <div className={style.privacyPopUpHeder}>
              <p>Manage cookie settings</p>
            </div>
            <div className={style.privacyPopUpBody}>
              <div className={style.privacyPopUpBodyText}>
                <p>
                We use cookies to distinguish you from other users of English Wagon. This helps us to provide you with a good experience when you browse English Wagon and also allows us to improve English Wagon. Find out more about our Cookie Policy which sets out information about the cookies on our site.
                </p>
                <a href="https://englishwagon.com/cookie-policy/" target="_blank" rel="noopener noreferrer">
            More Information
          </a>
              </div>
              <div className={style.privacyPopUpBodyButton}>
                <button onClick={handleAccept}>Accept all</button>
              </div>
              <div className={style.privacyPopUpBodyTable}>
                <div className={style.privacyTableLeft}>
                  <div className={style.privacyTableRow}>
                    <div className={style.privacyTableRowLeft}>
                    <p>Functionality cookies</p>
                    </div>
                    <div className={style.privacyTableRowRight}>
                    <Switch
                          onChange={(checked) => handleSwitchChange("functional", checked)}
                          checked={cookiePreferences.functional}
                          offColor="#888"
                          onColor="#595BDB"
                          checkedIcon={false}
                          uncheckedIcon={false}
                        />
                    </div>
                  </div>
                  
                  <div className={style.privacyTableRow}>
                    <div className={style.privacyTableRowLeft}>
                      <p>Analytical or performance cookies</p>
                    </div>
                    <div className={style.privacyTableRowRight}>
                    <Switch
                          onChange={(checked) => handleSwitchChange("performance", checked)}
                          checked={cookiePreferences.performance}
                          offColor="#888"
                          onColor="#595BDB"
                          checkedIcon={false}
                          uncheckedIcon={false}
                        />
                    </div>
                  </div>
                  <div className={style.privacyTableRow}>
                    <div className={style.privacyTableRowLeft}>
                      <p>Strictly necessary cookies</p>
                    </div>
                    <div className={style.privacyTableRowRight}>
                      <p>Always active</p>
                    </div>
                  </div>
                </div>
                <div className={style.privacyTableRight}>
                  <button
                    onClick={() => {
                      setPrivacyPopup(false);
                      localStorage.setItem('cookie-consent', 'rejected');
                      setIsVisible(false);
                    }}
                  >
                    Reject all
                  </button>
                  <button onClick={handleAccept}>Confirm my choice</button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  ));
};

export default Cookie;
